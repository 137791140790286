header {
    height: 25cm;
    padding-top: 10rem;
    overflow: hidden;
}

.header__container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 100%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 2rem;
    bottom: 10rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1cm;
}

.scroll__down {
    position: absolute;
    right: 2rem;
    bottom: 12rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}
*/

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}